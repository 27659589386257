<template>
  <div class="container kuan">
    <div class="main kuaiyi_page">
      
      <!-- <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div> -->
      <top-timer></top-timer>
      <div style="height:10px"></div>
      <section class="ky_flex">
        <textarea class="textarea" v-model="textcontent"></textarea>
       
      </section>
      <h4 class="blue" @click="sm_bool=!sm_bool" style="cursor: pointer;">快译说明 
        <i v-if="!sm_bool" class="el-icon-arrow-down"></i>
        <i v-if="sm_bool" class="el-icon-arrow-up"></i>
      </h4>
      <div class="atten" v-if="sm_bool">
          重要提示：
          <p class="atten_flex">
            <span class="xuhao">1.</span>
          <b> 注单文本必须同时包含 玩法，号码，金额 三要素，号码必须通过空格，逗号间隔开，同一单里面只能包含一种玩法；金额必须为阿拉伯数字，单位可为 元、块、米或倍；</b>
          </p>
          <p class="atten_flex">
            <span class="xuhao">2.</span>
            <b  > 多单同时快译时，必须换行输入；</b>
          </p>
          <p class="atten_flex">
            <span class="xuhao">3.</span>
            <ul style="text-indent: 32px;">
               <li> <b>使用示例</b> </li>
                            <li>直选 123，456，789 各5元</li>
                           
                            <li>组三 22, 12, 113, 123, 1234, 12345, 123456, 1234567, 12345678, 123456789, 全包 各7元</li>
                            <li>组六 12, 123, 1234, 12345, 123456, 1234567, 12345678, 123456789, 全包 各10元</li>
                            
                            <li>和值6, 8 各8元</li>
                            <li>跨1,2,3,4 各5元</li>
                            <li>单5元</li>
                            <li>单，双，大，小各5元</li>
                            <!-- <li>对子11,22,33,44各5元</li>
                            <li>对子全包 10元</li> -->
                            <li>独胆6,7,8,9各5元</li>
                            <li>一定位 1xx, x2x, xx3, xx4 各5元</li>
                            <li>二定位 11x, x22, 3x3, x55 各10元</li>
                            <li>一定位 百位1234 各10元</li>
                            <li>二定位 百位5432 十位456 各10元</li>
                          
                            <!-- <li>380, 813, 823, 383, 483, 583, 683, 783, 838, 839, 各3单2组 每注4元</li> -->
                          </ul>
          </p>
        </div>
      <div class="ky_btn">
        <span class="button" @click="sckuaiyion">生成号码明细</span>

      </div>

    </div>
    
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import kxmixins from "../mixins/kxmixinspl3";
import {
  zhixuan,
  kua,
  hezhi,
  yiziding,
  erziding,
  yizixian,
  zuxuan3,
  zuxuan6,
  fushi,
  daxiaodanshuang,
} from "./pl3callData.js";
import mixins from "../mixins/mainMixins";
import { md55 } from "../mixins/md5.js";
export default {
  name: "kuaiyi",
  props: [""],
  mixins: [mixins, kxmixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      sm_bool:false,
      ordertype: "zhixuan",
      ordertypeArr: [
        "直选",
        "组三",
        "组六",
        "和值",
        "跨",
        "单",
        "双",
        "大",
        "小",
        "独胆",
        "一定位",
        "二定位",
        "三定位",
        "复式",
      ],
      orderMeiju: {
        直选: "zhixuan",
        组三: "zusan",
        组六: "zuliu",
        和值: "hezhi",
        跨: "kua",
      },
      zhixuanInput: "",
      zusanInput: "",
      zuliuInput: "",
      kuaduInput: "",
      fushiInput: "",
      hezhiInput: "",
      dudanInput: "",
      yidingInput: "",
      erdingInput: "",
      dxdsInput: "",
      textcontent: "",
      badtextArr: [],
    };
  },
  watch: {
   
  },

  created() {
   
  },

  methods: {
    containsX(str) {
  return /x|X/.test(str);
},
    setOrderType(aa) {
      this.textcontent = "";
      this.resultData = [];
      this.badtextArr = [];
      this.ordertype = aa.value;
    },
    yanzhengZusanLabel(hmleng, elestr) {
      let b = true;
      if (hmleng == 1) {
        b = false;
      }
      // if(hmleng==3){
      //   if(elestr[0]==elestr[1]&&elestr[0]==elestr[2]){
      //      b=false
      //   }
      //  }
      if (hmleng >= 2) {
        //
        if (!/^(?!.*(\d).*\1)\d+$/.test(elestr)) {
          b = false;
        }
      }

      return b;
    },
    yanzhengZuLiuLabel(hmleng, elestr) {
      let b = true;
      if (hmleng < 3) {
        b = false;
      }
      // if(hmleng==3){
      //   if(elestr[0]==elestr[1]&&elestr[0]==elestr[2]){
      //     b=false
      //   }

      //  }
      if (hmleng >= 3) {
        //
        if (!/^(?!.*(\d).*\1)\d+$/.test(elestr)) {
          b = false;
        }
      }

      return b;
    },
    getDXDSData() {
      let transData = [];
      let strArr = this.fengeStrData(this.dxdsInput);
      strArr.forEach((elestr) => {
        // if (/^\d{2}X|\dX\d|X\d{2}$/.test(elestr)){

        let item = daxiaodanshuang.find((item) => item.label == elestr);
        if (item) {
          let newobj = Object.assign({}, item);

          transData.push(newobj);
        }

        //  }
      });
      return transData;
    },
    getERZDData() {
      let transData = [];
      let strArr = this.fengeStrData(this.textcontent);
      strArr.forEach((elestr) => {
        if (/^\d{2}X|\dX\d|X\d{2}$/.test(elestr)) {
          let item = erziding.find((item) => item.label == elestr);
          if (item) {
            let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              let newobj = Object.assign({}, item);

              transData.push(newobj);
            }
          }
        }
      });
      return transData;
    },
    getYZDData() {
      let transData = [];
      let strArr = this.fengeStrData(this.textcontent);
      strArr.forEach((elestr) => {
        if (/^(?:\d[X]{2}|[X]\d[X]|[X]{2}\d)$/.test(elestr)) {
          let item = yiziding.find((item) => item.label == elestr);
          if (item) {
            let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              let newobj = Object.assign({}, item);

              transData.push(newobj);
            }
          }
        }
      });
      return transData;
    },

    getYZXData() {
      let transData = [];
      let strArr = this.fengeStrData(this.textcontent);
      strArr.forEach((elestr) => {
        if (/^\d{1}$/.test(elestr)) {
          let item = yizixian.find((item) => item.label == elestr);
          if (item) {
            let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              let newobj = Object.assign({}, item);

              newobj.label = "独胆" + elestr;
              transData.push(newobj);
            }
          }
        }
      });
      return transData;
    },
    getHeZhiData() {
      let transData = [];
      let strArr = this.fengeStrData(this.textcontent);
      strArr.forEach((elestr) => {
        if (/^([0-9]|1\d|2[0-7])$/.test(elestr)) {
          let item = hezhi.find((item) => item.label == "和值" + elestr);
          if (item) {
            let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              let newobj = Object.assign({}, item);

              transData.push(newobj);
            }
          }
        }
      });
      return transData;
    },
    getFushiDaata() {
      let transData = [];
      let strArr = this.fengeStrData(this.textcontent);
      // console.log(strArr,425)
      strArr.forEach((elestr) => {
        let hmleng = elestr.length;

        if (/^(?!.*(\d).*\1)\d+$/.test(elestr) && hmleng >= 3) {
          let strarr = elestr.split("");
          let xiazhunum = strarr.join(",");

          let item = fushi.find((item) => item.label == hmleng);
          if (item) {
            // let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
            // if(hadIndex==-1){
            let newobj = Object.assign({}, item);
            newobj.label = "复式" + hmleng + "码(" + elestr + ")";
            newobj.num = xiazhunum;
            transData.push(newobj);
            // }
          } else {
            this.badtextArr.push(elestr);
          }
        } else {
          this.badtextArr.push(elestr);
        }
      });
      return transData;
    },
    getKuaduData() {
      let transData = [];
      let strArr = this.fengeStrData(this.textcontent);
      strArr.forEach((elestr) => {
        if (/^\d{1}$/.test(elestr)) {
          let item = kua.find((item) => item.label == "跨" + elestr);
          if (item) {
            let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              let newobj = Object.assign({}, item);
              newobj.label = "跨" + elestr;
              transData.push(newobj);
            }
          }
        }
      });

      return transData;
    },
    getZuLiuData() {
      let transData = [];
      let strArr = this.fengeStrData(this.textcontent);

      strArr.forEach((elestr) => {
        if (elestr.includes("+")) {
          // 胆拖  还不知道怎么写
        } else {
          // 没有+  不是胆拖
          let hmleng = elestr.length;
          let b = this.yanzhengZuLiuLabel(hmleng, elestr);

          if (b) {
            let strarr = elestr.split("");
            let xiazhunum = strarr.join(",");
            let item = zuxuan6.find((item) => item.label == hmleng);

            if (item) {
              // let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
              // if(hadIndex==-1){
              let newobj = Object.assign({}, item);
              newobj.num = xiazhunum;
              newobj.label = "组六" + newobj.label + "码(" + elestr + ")";
              transData.push(newobj);
              // }
            } else {
              this.badtextArr.push(elestr);
            }
          } else {
            this.badtextArr.push(elestr);
          }
        }
      });
      return transData;
    },
    getZusanData() {
      let transData = [];
      let strArr = this.fengeStrData(this.textcontent);
      strArr.forEach((elestr) => {
        if (elestr.includes("+")) {
          // 胆拖  还不知道怎么写
        } else {
          // 没有+  不是胆拖
          let hmleng = elestr.length;
          let b = this.yanzhengZusanLabel(hmleng, elestr);
          if (b) {
            let strarr = elestr.split("");
            let xiazhunum = strarr.join(",");
            let item = zuxuan3.find((item) => item.label == hmleng);

            if (item) {
              // let hadIndex=transData.findIndex(eas=>eas.ResultID==item.ResultID)
              // if(hadIndex==-1){
              let newobj = Object.assign({}, item);
              newobj.num = xiazhunum;
              newobj.label = "组三" + newobj.label + "码(" + elestr + ")";
              transData.push(newobj);
              // }
            } else {
              this.badtextArr.push(elestr);
            }
          } else {
            this.badtextArr.push(elestr);
          }
        }
      });
      return transData;
    },
    getZhiXuanData() {
      let transData = [];
      let strArr = this.fengeStrData(this.textcontent);

      strArr.forEach((elestr) => {
        if (/^\d{3}$/.test(elestr)) {
          let item = zhixuan.find((item) => item.label == elestr);
          if (item) {
            let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              transData.push(item);
            }
          } else {
            this.badtextArr.push(elestr);
          }
        } else {
          this.badtextArr.push(elestr);
        }
      });

      return transData;
    },
    sckuaiyion() {
      let transData=[]
      let gfsb=false
      let youwu=''
      //
      let allArr = this.textcontent.split("\n");
      for (let index = 0; index < allArr.length; index++) {
        let item = allArr[index];
        let hadIndexp = this.ordertypeArr.findIndex((oele) =>
          item.includes(oele)
        );

        if (hadIndexp == -1) { 
          gfsb=true
          this.tipon(item + "订单有误");
          // console.log(111)
          break
        }
          let orderStr = this.ordertypeArr[hadIndexp];
          // 输入的 直选
          if (orderStr == "直选") {
            let { onlyNumbers, money } = this.getXiaXhuAndMoney(hadIndexp, item);
           
            if (onlyNumbers.length == 0) {
              gfsb=true
              this.tipon(item + "订单有误");
              // console.log(555)
              break;
            }
         
            let b = onlyNumbers.some((xzele) =>{
              youwu=xzele
              return!/^\d{3}$/.test(xzele)});
            if (b) {
              gfsb=true
              let redStr = item.replace(new RegExp('\\b' + youwu + '\\b', 'g'), '<span style="color: red;">' + youwu + '</span>');
              this.tipon(redStr+'订单有误');
              // console.log(222)
              break;
            }
            onlyNumbers.forEach(onele=>{
              let item = zhixuan.find((item) => item.label == onele);
              if(item){
                let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              item.money=money
              transData.push(item);
            }
              }
            })
 
          }
          // 输入的 组三
          if(orderStr=='组三'){
            let { onlyNumbers, money } = this.getXiaXhuAndMoney(hadIndexp, item);
           
           if (onlyNumbers.length == 0) {
            gfsb=true
             this.tipon(item + "订单有误");
             break;
           }
        let b= onlyNumbers.some(elestr=>{
            let hmleng = elestr.length;
            youwu=elestr
            return !this.yanzhengZusanLabel(hmleng, elestr);
           })
           if (b) {
            gfsb=true
            let redStr = item.replace(new RegExp('\\b' + youwu + '\\b', 'g'), '<span style="color: red;">' + youwu + '</span>');
              this.tipon(redStr+'订单有误');
              break;
            }
            onlyNumbers.forEach(elestr=>{
              let hmleng = elestr.length;
              let strarr = elestr.split("");
            let xiazhunum = strarr.join(",");
            let item = zuxuan3.find((item) => item.label == hmleng);

            if (item) {
              
              let newobj = Object.assign({}, item);
              newobj.num = xiazhunum;
              newobj.money=money
              newobj.label = "组三" + newobj.label + "码(" + elestr + ")";
              if(elestr=='0123456789'){
                newobj.label='组三全包'
              }
            
              transData.push(newobj);
              
            }
         
       
            
            })

          }
          if(orderStr=='组六'){
            let { onlyNumbers, money } = this.getXiaXhuAndMoney(hadIndexp, item);
           
           if (onlyNumbers.length == 0) {
            gfsb=true
             this.tipon(item + "订单有误");
             break;
           }
           let b= onlyNumbers.some(elestr=>{
            let hmleng = elestr.length;
            youwu=elestr
            return !this.yanzhengZuLiuLabel(hmleng, elestr);
           })
           if (b) {
            gfsb=true
            let redStr = item.replace(new RegExp('\\b' + youwu + '\\b', 'g'), '<span style="color: red;">' + youwu + '</span>');
              this.tipon(redStr+'订单有误');
        break;
      }
      onlyNumbers.forEach(elestr=>{
              let hmleng = elestr.length;
              let strarr = elestr.split("");
            let xiazhunum = strarr.join(",");
            let item = zuxuan6.find((item) => item.label == hmleng);

            if (item) {
              
              let newobj = Object.assign({}, item);
              newobj.num = xiazhunum;
              newobj.money=money
              newobj.label = "组六" + newobj.label + "码(" + elestr + ")";
              if(elestr=='0123456789'){
                newobj.label='组六全包'
              }
            
              transData.push(newobj);
              
            }
         
       
            
            })

          }
          if(orderStr=='和值'||orderStr=='和值'){
            let { onlyNumbers, money } = this.getXiaXhuAndMoney(hadIndexp, item);
           
           if (onlyNumbers.length == 0) {
            gfsb=true
             this.tipon(item + "订单有误");
             break;
           }
           let b = onlyNumbers.some((xzele) =>{ 
               youwu=xzele
            return!/^([0-9]|1\d|2[0-7])$/.test(xzele)});
           if (b) {
            gfsb=true
            let redStr = item.replace(new RegExp('\\b' + youwu + '\\b', 'g'), '<span style="color: red;">' + youwu + '</span>');
              this.tipon(redStr+'订单有误');
              break;
            }
            onlyNumbers.forEach(onele=>{
              let item = hezhi.find((item) => item.label == '和值'+onele);
              if(item){
                let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              item.money=money
              transData.push(item);
            }
              }
            })






          }
          if(orderStr=='跨'||orderStr=='跨度'){
            let { onlyNumbers, money } = this.getXiaXhuAndMoney(hadIndexp, item);
           
           if (onlyNumbers.length == 0) {
            gfsb=true
             this.tipon(item + "订单有误");
             break;
           }
           let b = onlyNumbers.some((xzele) =>{ 
           youwu=xzele
           return!/^\d{1}$/.test(xzele)});
           if (b) {
            gfsb=true
                 let redStr = item.replace(new RegExp('\\b' + youwu + '\\b', 'g'), '<span style="color: red;">' + youwu + '</span>');
              this.tipon(redStr+'订单有误');
              break;
            }
            onlyNumbers.forEach(onele=>{
              let item = kua.find((item) => item.label == '跨'+onele);
              if(item){
                let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              item.money=money
              transData.push(item);
            }
              }
            })






          }
          if(orderStr=='独胆'||orderStr=='一字现'){
            let { onlyNumbers, money } = this.getXiaXhuAndMoney(hadIndexp, item);
           
           if (onlyNumbers.length == 0) {
            gfsb=true
             this.tipon(item + "订单有误");
             break;
           }
           let b = onlyNumbers.some((xzele) =>{ 
           youwu=xzele
           return!/^\d{1}$/.test(xzele)});
           if (b) {
            gfsb=true
               let redStr = item.replace(new RegExp('\\b' + youwu + '\\b', 'g'), '<span style="color: red;">' + youwu + '</span>');
              this.tipon(redStr+'订单有误');
              break;
            }
            onlyNumbers.forEach(onele=>{
              let item = yizixian.find((item) => item.label == onele);
              if(item){
                let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              let newobj = Object.assign({}, item);
              newobj.money=money
              newobj.label = "独胆" + onele;
              transData.push(newobj);
            }
              }
            })






          }
          if(orderStr=='一定位'||orderStr=='一定'||orderStr=='一字定位'){
            let { onlyNumbers, money } = this.getXiaXhuAndMoney(hadIndexp, item,'ding',1);
           
           if (onlyNumbers.length == 0) {
            gfsb=true
             this.tipon(item + "订单有误");
             // console.log(555)
             break;
           }
           let b = onlyNumbers.some((xzele) =>{ 
           youwu=xzele
           return!/^(?:\d[X]{2}|[X]\d[X]|[X]{2}\d)$/.test(xzele)});
           if (b) {
            gfsb=true
           let  newStr = item.replace(/x/g, "X");
            let redStr = newStr.replace(new RegExp('\\b' + youwu + '\\b', 'g'), '<span style="color: red;">' + youwu + '</span>');
             //  redStr = item.replace(/X/g, "x")
              this.tipon(redStr+'订单有误');
              break;
            }
           onlyNumbers.forEach(onele=>{
              let item = yiziding.find((item) => item.label == onele);
              if(item){
                let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              let newobj = Object.assign({}, item);
              newobj.money=money
             
              transData.push(newobj);
            }
              }
            })
          





          }
          if(orderStr=='二定位'||orderStr=='二定'||orderStr=='二字定位'){
            let { onlyNumbers, money } = this.getXiaXhuAndMoney(hadIndexp, item,'ding',2);
           
           if (onlyNumbers.length == 0) {
            gfsb=true
             this.tipon(item + "订单有误");
             // console.log(555)
             break;
           }
           let b = onlyNumbers.some((xzele) =>{ 
           youwu=xzele
           return!/^\d{2}X|\dX\d|X\d{2}$/.test(xzele)});
           if (b) {
            gfsb=true
              let  newStr = item.replace(/x/g, "X");
            let redStr = newStr.replace(new RegExp('\\b' + youwu + '\\b', 'g'), '<span style="color: red;">' + youwu + '</span>');
             //  redStr = item.replace(/X/g, "x")
              this.tipon(redStr+'订单有误');
              break;
            }
           onlyNumbers.forEach(onele=>{
              let item = erziding.find((item) => item.label == onele);
              if(item){
                let hadIndex = transData.findIndex(
              (eas) => eas.ResultID == item.ResultID
            );
            if (hadIndex == -1) {
              let newobj = Object.assign({}, item);
              newobj.money=money
             
              transData.push(newobj);
            }
              }
            })
          





          }
          if(/单|双|大|小/.test(item)){
            let money=0
            if (item.includes("各")) {
        item = item.replace("各", " 各");
      }
      let strArr = item.split(/[，, 。. \/，·-]/);
      
  // 取出下注金额
  let filteredItems = strArr.filter(
        (item) => item.includes("元") || item.includes("米")|| item.includes("块")|| item.includes("倍")
      ); //筛选出包含"元"或"米"的项
   
      let numbersWithUnits = filteredItems.map((item) => {
        let number = item.replace(/\D/g, "");
        return number;
      });
      money=Number(numbersWithUnits[0])
       daxiaodanshuang.forEach(oele=>{
        if(item.includes(oele.label)){
          let newobj=Object.assign({},oele)
          newobj.money=money
             
             transData.push(newobj);
        }
       })
      



          }
          // ---------------------
       
      }
  //  console.log(transData,'下注项All')
   if(transData.length>0&&!gfsb){
    this.tijiaoon(transData)
   }
      // console.log(allArr,496)
    },
    tijiaoon(transData){
      this.$nextTick(() => {
          let arr = [];
          transData.forEach((item) => {
            if (item.money) {
              let obj = {
                label: item.label,
             
                title: '',
                id: item.ResultID,
                money: Number(item.money),
              };
              if (item.num) {
                obj.num = item.num;
              }
              arr.push(obj);
            }
          });
      
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
            this.$store.commit("showOrHideXZTK", true);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
    },
    addSpacesForChars(arr, str) {
  // 构建正则表达式，匹配数组中的每个字符
  let regexPattern = `(${arr.join('|')})`;
  let regex = new RegExp(regexPattern, 'g');

  // 使用正则表达式和替换函数来在匹配的字符前后添加空格
  return str.replace(regex, ' $1 ');
},
    getXiaXhuAndMoney(hadIndex, item,par3,dingnum) {
      let orderStr = this.ordertypeArr[hadIndex];
      let newStr = item.replace(orderStr, "").trim();
      if (newStr.includes("各")) {
        newStr = newStr.replace("各", " 各");
      }
      if(newStr.includes('全包')){
        newStr = newStr.replace("全包", "0123456789");
      }
       if(newStr.includes('x')){
        
         newStr = newStr.replace(/x/g, "X");
          
      }
      let strArr = newStr.split(/[，, 。. \/，·-]/);
     
     
      // 取出下注项
  let onlyNumbers=[];
  if(par3=='ding'){
    // 一字定
    if(this.containsX(item)){
      onlyNumbers=strArr.filter((item) => this.containsX(item));
    }else if(/百|十|个/.test(item)){
      let baishigeArr= strArr.filter((item) => /百|十|个/.test(item))
      baishigeArr.forEach(bsgstr=>{
        let shuziStr= bsgstr.match(/\d+/g)[0];
        let shuziArr=shuziStr.split('')

        if(/百/.test(bsgstr)){
          shuziArr.forEach(strnum=>{
            let labstr=''
            if(dingnum==1){
              labstr=strnum+'XX'
            }else if(dingnum==2){

            }
           
            onlyNumbers.push(labstr)
          })
        }else if(/十/.test(bsgstr)){
          shuziArr.forEach(strnum=>{
            let labstr=''
            if(dingnum==1){
              labstr='X'+strnum+'X'
            }else if(dingnum==2){

            }
           
            onlyNumbers.push(labstr)
          })
        }else if(/个/.test(bsgstr)){
          shuziArr.forEach(strnum=>{
            let labstr=''
            if(dingnum==1){
              labstr='XX'+strnum
            }else if(dingnum==2){
              
            }
         
            onlyNumbers.push(labstr)
          })
        }
     
      })
      
    }
 
  } else{
    onlyNumbers = strArr.filter((item) => /^\d+$/.test(item));
  }
     // 取出下注金额
      let filteredItems = strArr.filter(
        (item) => item.includes("元") || item.includes("米")|| item.includes("块")|| item.includes("倍")
      ); //筛选出包含"元"或"米"的项
    
      let numbersWithUnits = filteredItems.map((item) => {
        let number = item.replace(/\D/g, "");
        return number;
      });

      return { onlyNumbers, money: Number(numbersWithUnits[0]) };
    },
    countX(str) {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === 'x' || str[i] === 'X') {
        count++;
    }
  }
  return count;
},
    shengcehngItem() {
      let resultData = [];
      this.badtextArr = [];
      if (!this.textcontent) {
        this.tipon("请输入内容");
        return;
      }
      if (this.ordertype == "zhixuan") {
        let arr = this.getZhiXuanData();
        resultData = resultData.concat(arr);
      }
      if (this.ordertype == "zusan") {
        let arr = this.getZusanData();
        resultData = resultData.concat(arr);
      }
      if (this.ordertype == "zuliu") {
        let arr = this.getZuLiuData();
        resultData = resultData.concat(arr);
      }
      if (this.ordertype == "kuadu") {
        let arr = this.getKuaduData();
        resultData = resultData.concat(arr);
      }
      if (this.ordertype == "fushi") {
        let arr = this.getFushiDaata();
        resultData = resultData.concat(arr);
      }
      if (this.ordertype == "hezhi") {
        let arr = this.getHeZhiData();
        resultData = resultData.concat(arr);
      }
      if (this.ordertype == "dudan") {
        let arr = this.getYZXData();
        resultData = resultData.concat(arr);
      }
      if (this.ordertype == "yiding") {
        let arr = this.getYZDData();
        resultData = resultData.concat(arr);
      }
      if (this.ordertype == "erding") {
        let arr = this.getERZDData();
        resultData = resultData.concat(arr);
      }

      // console.log(this.badtextArr,615)

      //  if(this.dxdsInput){
      //   let arr=this.getDXDSData()
      //   resultData=resultData.concat(arr)
      //  }

      console.log(resultData, 246);
      this.resultData = resultData;
    },
    tipon(str) {
      this.$confirm(str, "提示框", {
        confirmButtonText: "确定",
        cancelButtonText: "",
        showCancelButton: false,
        type: "error",
        dangerouslyUseHTMLString:true
      })
        .then(() => {})
        .catch(() => {});
    },
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    resetAllInput() {
      this.badtextArr = [];
      this.textcontent = "";
      this.zhixuanInput = "";
      this.resultData = [];
      this.zusanInput = "";
      this.zuliuInput = "";
      this.kuaduInput = "";
      this.fushiInput = "";
      this.hezhiInput = "";
      this.dudanInput = "";
      this.yidingInput = "";
      this.erdingInput = "";
      this.dxdsInput = "";
    },
    fengeStrData(str) {
      let strArr = [];
      if (str.includes(".")) {
        //  以.位分隔符
        strArr = str.split(".");
      } else if (str.includes(" ")) {
        // 以空格位分隔符
        strArr = str.split(" ");
      } else if (str.includes("/")) {
        // 以/位分隔符
        strArr = str.split("/");
      } else if (str.includes(",")) {
        // 以,位分隔符
        strArr = str.split(",");
      } else if (str.includes("-")) {
        // 以-位分隔符
        strArr = str.split("-");
      } else if (str.includes("，")) {
        // 以-位分隔符
        strArr = str.split("，");
      } else {
        strArr = [str];
      }
      return strArr;
    },
  },
};
</script>

<style scoped>
b {
  font-weight: bold;
}

.skin-blue .ky_btn .button {
  background: #2161b3;
}
.skin-red .ky_btn .button {
  background: #c2455c;
}
.skin-brown .ky_btn .button {
  background: #a18361;
}
.ky_btn .button {
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
}
.ky_btn {
  margin: 10px auto;
  display: flex;
  justify-content: center;
}
.atten_flex .xuhao {
  display: inline-block;
  width: 20px;
  min-width: 20px;
  text-align: center;
}
.atten_flex {
  margin-bottom: 4px;
  display: flex;
}
.ky_flex .atten {
  flex: 3;
  padding: 10px;
  box-sizing: border-box;
}
.ky_flex .textarea {
  flex: 8;
  padding: 4px;
  border: 1px solid lightgray;
}
.ky_flex {
  padding: 5px;
  display: flex;
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  border: 1px solid lightgray;
}
.norule {
  color: brown;
  margin-right: 10px;
  text-decoration: line-through;
}
.dantuo-sc.h2zd_ld {
  max-height: 250px !important;
}
.asdefda {
  padding: 10px;
  width: 100%;
  height: 292px;
  box-sizing: border-box;
}
.typeitem.selected {
  color: red;
}
.typeitem {
  margin-left: 6px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  cursor: pointer;
}
.font_bold {
  margin-right: 5px;
}
.flexinput {
  flex: 1;
}
.other_row.aoxin {
  padding: 1.4px;
  display: flex;
}
.type-f3d .greenball {
  /* background-image: url("../../assets/3d.png"); */
}
</style>